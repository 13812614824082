<template>
  <div class="orders">
    <el-tabs v-model="orderStates" @tab-click="navChange">
      <el-tab-pane label="全部订单" name="all" />
      <el-tab-pane label="待付款" name="0" />
      <el-tab-pane label="待发货" name="5" />
      <el-tab-pane label="待收货" name="6" />
      <el-tab-pane label="已完成" name="1" />
      <el-tab-pane label="签约" name="4" />
    </el-tabs>
    <!-- 签约 -->
    <div v-if="orderStates == 4">
      <el-table :data="studentInfo">
        <el-table-column label="合同ID" prop="contractId" align="center" />
        <el-table-column label="签约人姓名" prop="name" align="center" />
        <el-table-column label="签约手机号" prop="contact" align="center" />
        <el-table-column label="创建时间" prop="createTime" align="center" />
        <el-table-column label="合同状态" align="center">
          <template slot-scope="{ row }">
            {{ contractStatus[row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="createTime" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="row.status == 'SIGNING'" type="text" @click="submit(row)">{{ row.status == "SIGNING" ? "签约"
              : "查看" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="!orderStates || (orderStates && orderStates.length == 0)" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无订单哦~</div>
      </div>
    </div>
    <!-- 跳转内容 orderStates  1 全部订单-->
    <div v-else class="allOrder">
      <!-- 单个模块 -->
      <div v-for="(item, i) in list" :key="i" class="allOrders">
        <!-- 内容左 -->
        <template v-if="item.commodityType === 14">
          <div class="item_title">
            <div class="order_num">订单号：{{ item.orderNumber }}</div>
            <div class="item_tag">
              <!-- orderState订单状态(0待付款/2已退款 3退款中 4退款驳回 5  待发货  6待收货 1已完成) -->
              <div class="tab_type" v-if="item.orderState === 0">等待付款</div>
              <div class="tab_time1" v-else-if="item.orderState === 5">
                待发货
              </div>
              <div class="tab_time1" v-else-if="item.orderState === 6">
                待收货
              </div>
              <div class="tab_time1" v-else-if="item.orderState === 1">
                已完成
              </div>
              <div class="tab_time1" v-else-if="item.orderState === 7">
                已取消
              </div>
              <!-- <div class="tab_time1" v-else-if="item.orderState === 2">
              已退款
            </div> -->
              <div class="tab_time" v-if="item.orderState === 0">
                <el-statistic :value="new Date(item.countDownTime)" time-indices format="HH:mm:ss"
                  @finish="countDownTimeFinish(item)">
                </el-statistic>
              </div>
            </div>
          </div>
          <div v-for="(chil, chilIndex) in item.orderParticularsList" :key="chilIndex" v-if="chil.active"
            class="allOrdersCent">
            <div class="dis_flex_start">
              <div class="allOrdersCentL">
                <img v-if="chil.commodityType != 14" class="img" :src="chil.commodityImg" alt="" />
                <img v-else class="imgjc" :src="chil.commodityImg" alt="" />
              </div>
              <div class="right">
                <div class="right_left">
                  <div class="name">
                    <div class="nameType" v-if="item.commodityType === 14">
                      教材
                    </div>
                    <div class="commodityName">{{ chil.commodityName }}</div>
                  </div>
                  <div class="tip">
                    <div>
                      共<span class="tip_tab"> {{ chil.commodityNumber }} </span>件
                    </div>
                    <div class="freight" v-if="
                      (item.orderParticularsList.length === 1 ||
                        item.showind) &&
                      item.commodityType === 14
                    ">
                      运费￥{{ item.freight }}
                    </div>
                  </div>
                  <div class="unfold" @click="shows(item)" v-if="item.showind">
                    展开<i class="el-icon-arrow-down"></i>
                  </div>
                </div>
                <div class="right_right">
                  <div class="right_price">
                    合计<span class="price">
                      ￥
                      {{ item.paidPrice.toFixed(2) }}
                    </span>
                  </div>
                  <div class="right_btn" v-if="
                    (item.orderParticularsList.length === 1 ||
                      item.showind) &&
                    item.commodityType === 14
                  ">
                    <div class="btn1" v-if="item.orderState === 0" @click="cancelOrder(item.id)">
                      取消订单
                    </div>
                    <div class="btn2" v-if="item.orderState === 0" @click="dingdn(item)">
                      付款
                    </div>
                    <div class="btn1" v-if="item.orderState === 5" @click="toDetails(chil)">
                      催发货
                    </div>
                    <div class="btn1" v-if="
                      item.orderState === 1 &&
                      item.commodityType === 14 &&
                      item.isEvaluate === 0
                    " @click="evaluateClick(item)">
                      去评价
                    </div>
                    <div class="btn1" v-if="
                      item.orderState === 1 &&
                      item.commodityType === 14 &&
                      item.isEvaluate === 1
                    " @click="examineClick(item)">
                      查看评价
                    </div>
                    <div class="btn1" v-if="item.orderState === 6" @click="getShipTraceInfo(item.id)">
                      物流信息
                    </div>
                    <div class="btn1" v-if="item.orderState === 1" @click="getShipTraceInfo(item.id)">
                      查看物流
                    </div>
                    <div class="btn2" v-if="item.orderState === 6" @click="confirm(item)">
                      确认收货
                    </div>
                    <!-- <div
                    class="btn1"
                    @click="deleteOrder(item.id)"
                    v-if="item.orderState === 7"
                  >
                    删除订单
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="item.orderParticularsList.length > 1 && !item.showind">
            <div class="bottom_tip">
              <div class="freight" v-if="item.commodityType === 14">
                运费￥{{ item.freight }}
              </div>
              <div class="open" @click="shows(item)" v-if="!item.showind">
                收起<i class="el-icon-arrow-up"></i>
              </div>
            </div>
            <div class="right_btn" v-if="item.commodityType === 14">
              <div class="btn1" v-if="item.orderState === 0" @click="cancelOrder(item.id)">
                取消订单
              </div>
              <div class="btn2" v-if="item.orderState === 0" @click="dingdn(item)">
                付款
              </div>
              <div class="btn1" v-if="item.orderState === 5" @click="toDetails(chil)">
                催发货
              </div>
              <div class="btn1" v-if="
                item.orderState === 1 &&
                item.commodityType === 14 &&
                item.isEvaluate === 0
              " @click="evaluateClick(item)">
                去评价
              </div>
              <div class="btn1" v-if="
                item.orderState === 1 &&
                item.commodityType === 14 &&
                item.isEvaluate === 1
              " @click="examineClick(item)">
                查看评价
              </div>
              <div class="btn1" v-if="item.orderState === 6" @click="getShipTraceInfo(item.id)">
                物流信息
              </div>
              <div class="btn1" v-if="item.orderState === 1" @click="getShipTraceInfo(item.id)">
                查看物流
              </div>
              <div class="btn2" v-if="item.orderState === 6" @click="confirm(item)">
                确认收货
              </div>
              <!-- <div
              class="btn1"
              @click="deleteOrder(item.id)"
              v-if="item.orderState === 7"
            >
              删除订单
            </div> -->
            </div>
          </div>
        </template>
        <template v-else>
          <div class="knowledge" v-for="(chil, index) in item.orderParticularsList" :key="index">
            <div class="left">
              <el-image class="knowledge_img" :src="item.commodityImg"></el-image>
              <div class="succeed" v-if="item.orderState === 1 || item.orderState === 4">
                交易成功
              </div>
              <div class="succeed1" v-if="item.orderState === 0">待付款</div>
            </div>
            <div class="knowledge_right">
              <div class="right_right1">
                <div class="name">{{ chil.commodityName }}</div>
                <div class="tip_num">
                  <div class="tip" v-if="chil.productLine == '5'">1V1直播</div>
                  <div class="tip" v-else>
                    {{ type[chil.commodityType] }}
                  </div>
                  <span class="num">x{{ item.orderParticularsList.length }}</span>
                </div>
                <div class="orderNum">
                  订单号：{{ chil.orderId }}
                  <div class="cost" v-if="item.orderState === 3">退费中</div>
                  <div class="cost" v-if="item.orderState === 2">退款成功</div>
                </div>
              </div>
              <div v-if="index == 0" class="right_left_1">
                <div class="payment">
                  实付款￥<span class="paidPrice">{{ item.paidPrice }}</span>
                </div>
                <div class="discounts">优惠券-￥{{ item.discountPrice }}</div>
                <div class="more_btn">
                  <!-- <div class="btn" v-if="item.orderState === 1">联系班主任</div> -->
                  <div class="btn" v-if="
                    item.orderState === 1 &&
                    (domain.isShow == 1 ? item.targetTime < 7 : false)
                  " @click="refundI(item)">
                    申请退费
                  </div>

                  <div v-if="
                    item.orderState == 1 &&
                    item.status == 1 &&
                    item.paidPrice > 0
                  " style="margin-right: 16px" class="btn2" @click="toinvoice(item)">
                    申请开票
                  </div>

                  <div v-if="item.status == 0 || item.status == 2" style="margin-right: 16px" class="btn2"
                    @click="seeinvoice(item)">
                    查看发票
                  </div>
                  <div class="btn2" v-if="item.orderState === 1 || item.orderState === 4" @click="toDetailss(chil)">
                    去学习
                  </div>
                  <div class="btn3" v-if="item.orderState === 2">已销课</div>
                  <div class="btn1" v-if="item.orderState === 0" @click="dingdn(item)">
                    立即付款
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!list || (list && list.length == 0)" class="nothing">
        <div class="nothing-text">暂无订单哦~</div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination" v-show="total > 0" :total="total" :current-page="pageNum"
        :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
    <el-dialog top="5vh" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <div class="iframeBox">
        <iframe class="iframe" :src="contractUrl" />
      </div>
    </el-dialog>
    <el-dialog class="addsdialogVisible" title="物流信息" center :visible.sync="addsdialogVisible">
      <div class="logistics">
        <div class="logistics_title">
          <div class="code">
            <img :src="Couriers[logisticsDetails.shipperCode]" alt="" />
            {{ shipperCode }}
            {{ logisticsDetails.logisticCode }}
          </div>
          <div link class="copy" @click="onCopy(logisticsDetails.logisticCode)">
            复制
          </div>
        </div>
        <div class="logistics_item" v-for="(item, index) in logisticsList" :key="index">
          <div class="acceptTime">{{ item.acceptTime }}</div>
          <div class="dian_line">
            <div class="dian" :class="index == 0 ? 'active' : ''"></div>
            <div class="line"></div>
          </div>

          <div class="acceptStation">{{ item.acceptStation }}</div>
        </div>
      </div>
      <!-- <div class="iframeBox">
          <iframe class="iframe" :src="contractUrl" />
        </div> -->
    </el-dialog>
    <!-- 评价 -->
    <el-dialog title="您对本次订单满意嘛？" :visible.sync="appraiseVisible" width="400px" :before-close="handleClose"
      :show-close="false" class="appraiseVisible">
      <el-image class="appraiseVisible_img" :src="require('@/assets/img/textmall/xiaohai.png')"></el-image>
      <div class="appraise">
        <el-rate v-model="appraiseObj.rate" :show-score="appraiseObj.rate > 0" allow-half void-color="#999999"
          :score-template="appraiseObj.rate * 2 + '分'"></el-rate>
      </div>
      <div class="centent">
        <el-input type="textarea" :rows="2" placeholder="随便说点什么吧~" v-model="appraiseObj.content">
        </el-input>
      </div>
      <div class="flie">
        <el-upload ref="upload" :action="imgAction()" :headers="Domainjwt" name="upload" :limit="3"
          list-type="picture-card" :file-list="appraiseObj.fileList" :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess" :on-remove="handleRemove" :class="{ uploadDisplay: uploadDisabled }">
          <i class="el-icon-plus" />
        </el-upload>
      </div>
      <div class="footer">
        <el-button class="btn" @click="appraiseSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!-- 查看评价 -->
    <el-dialog title="您的订单评价" :visible.sync="examineVisible" width="400px" :before-close="handleClose"
      :show-close="false" class="examine">
      <el-image class="examine_img" :src="require('@/assets/img/textmall/xiaohai.png')"></el-image>
      <div class="details">
        <div class="rate">
          <el-rate :value="appraiseObj.rate" disabled show-score text-color="#ff9900"
            :score-template="appraiseObj.rate * 2 + '分'">
          </el-rate>
        </div>
        <div class="title">{{ appraiseObj.content }}</div>
        <div class="detail_img" v-if="appraiseObj.fileList.length > 0">
          <div class="item_img" v-for="(item, index) in appraiseObj.fileList" :key="index">
            <el-image class="img" :src="item" :preview-src-list="appraiseObj.fileList">
            </el-image>
            <!-- <el-image  :src="item"></el-image> -->
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 联系班主任 -->
    <el-dialog :visible.sync="popupVisible" width="500px" :before-close="popupClose">
      <div class="popup_wrap">
        <div class="CR_code_box">
          <img class="QR_code_img" :src="QRCodeUrl" alt="" />
          <div class="block upper_left" />
          <div class="block top_right" />
          <div class="block lower_left" />
          <div class="block lower_right" />
        </div>
        <div class="contact_details">
          <img class="icon" src="@/assets/img/Home/phone.png" alt="" />
          <span>联系方式：{{ phoneNumber }}</span>
        </div>
        <div class="text_box">
          <span>可</span>
          <span class="red_text">扫码上方二维码</span>
          <span>或</span>
          <span class="red_text">拨打手机号</span>
          <span>联系班主任</span>
        </div>
      </div>
    </el-dialog>
    <!-- 申请退费 -->
    <el-dialog :visible.sync="premiumVisible" width="500px" :before-close="premiumClose" title="温馨提示" class="premium">
      <div class="premium_title">
        <div class="premiumleft"><i class="el-icon-warning"></i></div>
        <div class="premiumTitle">选择退费后，不可取消退费，确认要继续吗？</div>
      </div>
      <div class="footer">
        <el-button class="btn" @click="premiumClose">取消</el-button>
        <el-button class="btn1" @click="premiumSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog class="ssss" :visible.sync="invoiceDio" width="630px" title="申请发票">
      <invoice @isOK="isOK" v-if="invoiceDio" :invoiceItem="invoiceItem"></invoice>
    </el-dialog>
    <el-dialog class="ssss" :visible.sync="seeinvoiceDio" width="630px" title="查看发票">
      <invoiceDetail @isOK="isOK" v-if="seeinvoiceDio" :invoiceItem="invoiceItem">
      </invoiceDetail>
    </el-dialog>
  </div>
</template>
<script>
import { getClassOrderList } from "@/api/user";
import {
  setReady,
  getToken,
  getInfo,
  getDomainLogo,
  getDomain,
  getDomainToken,
} from "@/api/cookies";
import { showTypeEnum, Courier } from "@/api/emun";
import {
  deleteTextBookOrder,
  getShipTraceInfo,
  updateConsigneeStatus,
  addSelfBookEvaluate,
  getStudentEvaluateDetail,
  cancelTextBookOrder,
} from "@/api/mykecheng";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import invoice from "@/views/mine/my/invoice.vue";
import invoiceDetail from "@/views/mine/my/invoiceDetail.vue";

import Vue from "vue";
export default {
  components: { invoice, invoiceDetail },

  data() {
    return {
      invoiceList: "",
      invoiceItem: "",
      invoiceDio: false,
      seeinvoiceDio: false,

      userId: null, // 用户信息
      tenantId: null,
      orderStates: "all",
      list: [],
      logisticsList: [],
      logisticsDetails: {},
      pageSize: 8,
      Couriers: Courier,
      // 订单 第几页
      pageNum: 1,
      total: 0,
      type: showTypeEnum,
      userInfo: null,
      studentInfo: [],
      contractStatus: {
        DRAFT: "草稿",
        RECALLED: "已撤回",
        SIGNING: "签署中",
        REJECTED: "已退回",
        COMPLETE: "已完成",
        EXPIRED: "已过期",
        FILLING: "拟定中",
        FAILED: "签署失败",
        INVALIDING: "作废中",
        INVALIDED: "已作废",
      },
      Domainjwt: {
        Domainjwt: getDomainToken(),
      },
      dialogVisible: false,
      contractUrl: null,
      domain: null,
      orderDownTime: null,
      addsdialogVisible: false,
      popupVisible: false,
      QRCodeUrl: "",
      min: "",
      sed: "",
      phoneNumber: "",
      // 评价
      appraiseVisible: false,
      examineVisible: false,
      appraiseObj: {
        orderNumber: "",
        content: "",
        rate: 0,
        fileList: [],
      },
      commodityType: null,
      rate: 0,
      isSHow: true,
      premiumVisible: false,
      premium: {},
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.domain = getDomain();
    this.getOrderIsShow();
    this.userId = localStorage.getItem("userId");
    this.tenantId = JSON.parse(localStorage.getItem("userInfo")).tenantId;
    // 调用获取学生订单信息接口
    await this.getList(); // (全部)
  },
  computed: {
    uploadDisabled() {
      return this.appraiseObj.fileList.length >= 3;
    },
    shipperCode() {
      if (this.logisticsDetails.shipperCode === "YTO") return "圆通快递";
      else if (this.logisticsDetails.shipperCode === "百世") return "百世快递";
      else if (this.logisticsDetails.shipperCode === "STO") return "申通快递";
    },
  },
  methods: {
    isOK() {
      this.getList();

      this.invoiceDio = false;
    },
    async seeinvoice(item) {
      this.invoiceItem = item;
      this.seeinvoiceDio = true;
    },
    // 倒计时结束
    countDownTimeFinish(val) {
      this.cancelOrder(val.id);
    },
    beforeAvatarUpload(file) {
      const allowImgType = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/jpg",
      ];
      if (!allowImgType.includes(file.type)) {
        this.$message.error("上传文件类型不符,仅支持jpg/png/gif格式图片");
        return false;
      }
    },
    // 图片上传
    handleAvatarSuccess(res, file, fileList) {
      if (res.isSuccess) {
        if (!this.appraiseObj.fileList) {
          this.appraiseObj.fileList = [];
        }
        // const data = { url:, path: "" };
        this.appraiseObj.fileList.push(res.path);
      }
    },
    /* 移除2 */
    handleRemove(file, fileList) {
      this.appraiseObj.fileList.forEach((item, index) => {
        if (item === file.response.path) {
          this.appraiseObj.fileList.splice(index, 1);
        }
      });
      this.$forceUpdate(this.appraiseObj.fileList);
    },
    // 超出限制
    handleExceed() {
      this.isSHow = false;
    },
    // 提交
    async appraiseSubmit() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const res = await addSelfBookEvaluate({
        stuName: user.nickName,
        stuImg: user.img,
        mobile: user.mobile,
        stuId: user.id,
        orderNumber: this.appraiseObj.orderNumber,
        content: this.appraiseObj.content,
        img: this.appraiseObj.fileList.toString(),
        evaluateNum: this.appraiseObj.rate * 2,
        port: "pc",
      });
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.appraiseVisible = false;
        this.appraiseObj = {
          orderNumber: "",
          content: "",
          rate: 0,
          fileList: [],
        };
        this.getList();
      } else {
        this.$message.error(res.msg);
        this.appraiseVisible = false;
      }
    },
    toDetails(item) {
      this.$alert("您已催发货成功，已通知工作人员，请您耐心等待~", "提示", {
        confirmButtonText: "我知道了",
        center: true,
      });
    },
    // 标签页修改
    navChange() {
      this.pageSize = 8;
      this.pageNum = 1;
      this.list = [];
      if (this.orderStates == "4") {
        this.getStudentInfo();
      } else {
        this.getList();
      }
    },
    // 调用获取学生订单信息接口(全部)
    /* orderState 0 未购买 1 已购买 2 已退费 3 待退费 */
    getList() {
      getClassOrderList(
        this.pageSize,
        this.pageNum,
        this.userId,
        this.orderStates == "all" ? "" : this.orderStates,
        this.tenantId
      ).then((res) => {
        this.list = res.rows;
        console.log("=====================", res.rows);
        this.list.map((item) => {
          item.showind = false;
          if (
            item.orderParticularsList.length > 1 &&
            item.commodityType == 14
          ) {
            this.shows(item);
          }
          if (item.orderParticularsList.length === 1) {
            item.orderParticularsList[0].active = true;
          }
          const payTime = new Date(item.payTime);
          if (
            new Date(item.countDownTime) > new Date() &&
            item.orderState == 0
          ) {
            this.orderDownTime =
              new Date(item.countDownTime).getTime() - new Date().getTime();
            this.min = new Date(this.orderDownTime).getMinutes();
            this.sed = new Date(this.orderDownTime).getMinutes();
            let timer = null;
            timer = setInterval(() => {
              this.sed--;
              if (this.sed == 0) {
                this.min--;
                this.sed = 60;
                if (this.min < 0) {
                  item.orderdue = true;
                  clearInterval(timer);
                  return;
                }
              }
            }, 1000);
          }
          const currentTime = new Date();
          const targetTime = (currentTime - payTime) / 1000 / 60 / 60 / 24;
          this.$set(item, "targetTime", targetTime);
        });
        this.total = res.total;
      });
    },

    /*
      查询学生签约信息
      contractId	是	合同ID
      contact	是	签约者联系方式
    */
    getStudentInfo() {
      const data = {
        contact: this.userInfo.mobile,
        /* contact:'13287740516' */
      };
      orderClient.selectQysStudent(data).then((res) => {
        this.studentInfo = res.data;
      });
    },
    /*
      点击签约
      contractId	是	合同ID
      contact	是	签约者联系方式
    */
    submit(row) {
      const data = {
        contractId: row.contractId,
        contact: row.contact,
      };
      orderClient.contractPageurl(data).then((res) => {
        if (res.code == 0 && res.data) {
          this.contractUrl = res.data;
          this.dialogVisible = true;
        } else {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
    },
    /* 关闭签约弹窗 */
    handleClose() {
      this.dialogVisible = false;
      this.appraiseVisible = false;
      this.examineVisible = false;
      this.appraiseObj = {
        orderNumber: "",
        content: "",
        rate: 0,
        fileList: [],
      };
      this.getStudentInfo();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 删除订单
    async deleteOrder(val) {
      const res = await deleteTextBookOrder(val);
      if (res.code == 0) {
        this.$message.success("删除订单成功");
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 取消订单
    async cancelOrder(val) {
      const res = await cancelTextBookOrder(val);
      if (res.code === 0) {
        this.$message.success("取消订单成功");
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
    },
    async shows(val) {
      val.showind = !val.showind;
      if (val.showind) {
        this.list.forEach((e) => {
          if (e == val) {
            val.orderParticularsList.forEach((p, ind) =>
              ind == 0
                ? this.$set(p, "active", val.showind)
                : this.$set(p, "active", !val.showind)
            );
          }
        });
      } else {
        this.list.forEach((e) => {
          if (e == val) {
            val.orderParticularsList.forEach((p, ind) =>
              this.$set(p, "active", !val.showind)
            );
          }
        });
      }
    },
    evaluateClick(val) {
      this.title = "您对本次订单满意嘛？";
      this.appraiseVisible = true;
      this.appraiseObj.orderNumber = val.orderNumber;
    },
    async examineClick(val) {
      const res = await getStudentEvaluateDetail({
        orderNumber: val.orderNumber,
      });
      if (res.code === 0) {
        if (res.data[0].img !== "") {
          this.appraiseObj.fileList = res.data[0].img.split(",");
        }
        this.appraiseObj.rate = res.data[0].evaluateNum / 2;
        this.appraiseObj.content = res.data[0].content;
        this.examineVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 确认收货
    async confirm(val) {
      const res = await updateConsigneeStatus(val.id);

      if (res.code === 0) {
        this.$message.success("确认收货成功");
        this.getList();
        // this.$forceUpdate();
      } else {
        this.$message.error(res.msg);
      }
    },
    async getShipTraceInfo(val) {
      const res = await getShipTraceInfo(val);
      if (res.code == 0) {
        this.logisticsList = res.data.traces;
        this.logisticsDetails = res.data;
        this.addsdialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 全部订单 去支付
    dingdn(item) {
      if (item.commodityType == 14) {
        this.$router.push({
          path: "/mall/orderView",
          query: { id: item.orderNumber },
        });
      } else {
        this.$router.push({
          name: "个人中心订单页",
          params: { orderNumber: item.orderNumber, img: item.commodityImg },
        });
      }
    },
    onCopy(val) {
      this.$copyText(val).then(
        (e) => {
          console.log("复制成功：", e);
        },
        (e) => {
          console.log("复制失败：", e);
        }
      );
    },
    // 去学习
    goLearn(row) {
      Vue.prototype.goTypeDetail(
        row.commodityType,
        row.commodityId,
        row.isSkip,
        row.commodityName
      );
    },
    /* 退费 */
    refundI(item) {
      this.premium = item;
      this.premiumVisible = true;
      // this.$confirm("选择退费后，不可取消退费，确认要继续吗？", "温馨提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     const data = {
      //       orderId: item.id,
      //     };
      //     orderClient.mAddOrder(data).then((res) => {
      //       if (res.code == 0) {
      //         this.$message({
      //           type: "success",
      //           message: "申请成功，请耐心等待审核!",
      //         });
      //         this.getList();
      //       } else {
      //         this.$message({
      //           type: "error",
      //           message: res.msg,
      //         });
      //       }
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },
    // 确定
    premiumSubmit() {
      orderClient.mAddOrder({ orderId: this.premium.id }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "申请成功，请耐心等待审核!",
          });
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
      this.premiumVisible = false;
    },
    // 取消
    premiumClose() {
      this.premiumVisible = false;
      this.$message({
        type: "info",
        message: "已取消",
      });
    },
    // 去学习
    toDetailss(item) {
      if (item.productLine || item.commodityType) {
        // this.$router.push({ path: '/seventhPage/mycurriculum', query: { id: item.commodityId }})
        window.open("/all/mycurriculum", "_blank");
        return;
      }
      if (item.productLine === "5") {
        this.$router.push({
          path: "/VIP/oneToOneClass",
          query: {
            id: item.commodityId,
          },
        });
        return;
      }
      Vue.prototype.goTypeDetail(
        item.commodityType,
        item.commodityId,
        undefined,
        undefined,
        undefined,
        item.productLine
      );
    },
    toinvoice(item) {
      this.invoiceItem = item;
      this.invoiceDio = true;
    },

    // 联系班主任
    openPopup() {
      this.popupVisible = true;
    },
    popupClose() {
      this.popupVisible = false;
    },
    getOrderIsShow() {
      const data = {
        userid: this.userInfo.id,
      };
      orderClient.getOrderIsShow(data).then((res) => {
        this.QRCodeUrl = res.data?.wechatCode;
        this.phoneNumber = res.data?.phoneNum;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__headerbtn {
  font-size: 26px;
}

/deep/ .el-upload--picture-card {
  border: 1px solid #c0ccda;
}

.premium {
  /deep/ .el-dialog {
    width: 879px;
    height: 160px;
    background: #ffffff;
    border-radius: 12px;
    opacity: 1;
    margin-top: 40vh !important;

    .premium_title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      .premiumleft {
        color: #e6a23c;
        font-size: 26px;
      }

      .premiumTitle {
        font-size: 16px;
        margin-left: 5px;
        margin-top: -5px;
      }
    }

    .footer {
      text-align: right;
      margin-top: 10px;

      .btn1 {
        background-color: #409eff;
        color: #fff;
        border: none;
      }
    }
  }
}

/deep/ .addsdialogVisible {
  .el-dialog {
    width: 879px;
    height: 645px;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 12px;
    opacity: 1;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
      letter-spacing: 2px;
    }

    .logistics {
      .logistics_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 811px;
        height: 59px;
        background: #f6f6f6;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        padding: 10px 20px;
        margin-bottom: 20px;

        .code {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          img {
            margin-right: 10px;
          }
        }

        .copy {
          width: 36px;
          height: 25px;
          cursor: pointer;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff5d51;
        }
      }

      .logistics_item {
        display: flex;
        align-items: flex-start;
        justify-content: start;

        .acceptTime {
          font-size: 16px;
          width: 173px;
          margin-top: 6px;
          text-align: right;
          color: #333;
        }

        .dian_line {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
          height: auto;

          .dian {
            width: 14px;
            height: 14px;
            background: #ffffff;
            border-radius: 50%;
            opacity: 1;
            border: 1px solid #ff5d51;
            margin: 0px 10px 0;
          }

          .active {
            background: #ff5d51;
          }

          .line {
            width: 0px;
            height: 55px;
            border: 1px solid #eeeeee;
          }
        }

        .acceptStation {
          width: 604px;
          line-height: 27px;
          font-size: 16px;
          color: #333;
        }
      }

      .logistics_item:nth-last-child(1) {
        padding-bottom: 30px;

        .line {
          width: 0px;
          height: 0px;
          // border: 1px solid #eeeeee;
        }
      }
    }
  }
}

/deep/ .el-pagination {
  padding-bottom: 20px;
  margin: 0 auto !important;
}

/deep/ .uploadDisplay {
  .el-upload--picture-card {
    display: none;
  }
}

// 缩宽
.orders {
  width: 100%;
  height: 100%;
  // display: table;
  margin: auto;
  padding: 0px 24px;

  // 标题
  .or-title {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #27323f;
  }

  // 跳转盒子
  // 跳转盒子
  .basicsName {
    width: 100%;
    height: 38px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    border-bottom: 1px solid #eee;

    .basicsNames {
      /*   width: 65px; */
      height: 100%;
      margin-right: 41px;
      cursor: pointer;

      .basicsNamesTop {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .basicsNamesTops {
        color: #333333;
      }

      .basicsNamesCen {
        width: 24px;
        height: 2px;
        margin: 14px auto 0px;
        background: #ef0d0d;
        border-radius: 1px;
      }
    }
  }

  // 跳转内容 orderStates = 1 全部订单
  .allOrder {
    width: 100%;
    height: 100%;
    margin-top: 24px;

    .p5 {
      margin-top: 20px;
      padding: 0 20px;
      display: flex;

      .freight {
        // width: 57px;
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #333333;

        span {
          cursor: pointer;
        }

        img {
          display: inline-block;
          width: 12px;
          margin-left: 5px;
          padding-bottom: 2px;
        }
      }

      .refundBtn {
        height: 28px;
        width: 80px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ececec;
        cursor: pointer;
        margin-left: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        text-align: center;
      }

      .goLearnBtn {
        border: 1px solid #4a6af0;
        color: #4a6af0;
      }

      .godd {
        background: #4a6af0;
        color: #fff;
      }
    }

    // 模块
    .allOrders {
      width: 1148px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 20px auto;

      .knowledge {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .left {
          width: 192px;
          height: 108px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          position: relative;

          .knowledge_img {
            width: 192px;
            height: 108px;
            border-radius: 8px;
          }

          .succeed {
            position: absolute;
            width: 76px;
            height: 24px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 8px 0px 8px 0px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
          }

          .succeed1 {
            position: absolute;
            width: 62px;
            height: 24px;
            background: #ff5e51;
            border-radius: 8px 0px 8px 0px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
          }
        }

        .knowledge_right {
          width: calc(100% - 204px);
          margin-left: 12px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .right_right1 {
            .name {
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }

            .tip_num {
              margin-top: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .tip {
                width: 64px;
                height: 20px;
                background: #fff2e9;
                border-radius: 2px 2px 2px 2px;
                padding: 4px 8px;
                opacity: 1;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #ff7b1c;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .num {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-left: 5px;
              }
            }

            .orderNum {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              margin-top: 20px;
              display: flex;
              align-items: center;

              .cost {
                margin-left: 5px;
                border: 1px solid #ff5e51;
                border-radius: 5px;
                padding: 1px 5px;
                color: #ff5e51;
              }
            }
          }

          .right_left_1 {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;

            .payment {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333;

              .paidPrice {
                font-size: 24px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }
            }

            .discounts {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ff5e51;
              margin-top: 12px;
            }

            .more_btn {
              display: flex;
              align-items: center;
              margin-top: 20px;

              .btn {
                width: 88px;
                height: 30px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #dddddd;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 16px;
                cursor: pointer;
              }

              .btn1 {
                width: 88px;
                height: 30px;
                background: #ff5e51;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }

              .btn2 {
                width: 88px;
                height: 30px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #ff5e51;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ff5e51;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }

              .btn3 {
                width: 88px;
                height: 30px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #dddddd;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      .item_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;

        .order_num {
          font-size: 14px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .item_tag {
          display: flex;
          align-items: center;
          position: relative;

          .tab_time {
            width: 156px;
            height: 26px;
            background: rgba(255, 93, 81, 0.1);
            border-radius: 17px 17px 17px 17px;

            /deep/ .el-statistic {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff5d51;
              padding-right: 13px;

              .number {
                text-align: right;
                line-height: 26px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ff5d51;
                padding-right: 13px;
                margin-left: 100px;
              }
            }
          }

          .tab_time1 {
            height: 26px;
            background: rgba(255, 93, 81, 0.1);
            border-radius: 17px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff5d51;
            padding: 3px 7px;
          }

          .tab_type {
            position: absolute;
            top: 0;
            left: 0;
            height: 26px;
            background: #ff5d51;
            border-radius: 17px 0px 17px 17px;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            padding: 3px 11px;
            z-index: 2;
          }
        }
      }

      .dd_line {
        margin: 0 auto;
        width: 800px;
        height: 0px;
        opacity: 1;
        border: 1px solid #eeeeee;
      }

      .allOrdersCent {
        width: 100%;
        background-color: #fff;
        padding-top: 20px;
        height: auto;

        // 左
        .allOrdersCentL {
          width: 100px;
          height: 100px;
          background: #f5f7f9;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .img,
          .imgjc {
            width: 69px;
            height: 92px;
          }
        }

        .right {
          width: 968px;
          margin-left: 30px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          // margin-bottom: -10px;
          border-bottom: 1px solid #eeeeee;

          .right_left {
            .name {
              font-size: 16px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
            }

            .commodityName {
              width: 700px;
              white-space: normal;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .nameType {
              display: block;
              width: 34px;
              height: 16px;
              background: rgba(255, 93, 81, 0.1);
              border-radius: 3px;
              font-size: 12px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: rgba(255, 93, 81, 1);
              text-align: center;
              line-height: 16px;
              margin-right: 6px;
              margin-top: 4px;
            }

            .tip {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              margin-top: 20px;

              // letter-spacing: 3px;
              .tip_tab {
                color: #ff5d51;
              }

              .freight {
                letter-spacing: 1px;
                margin-left: 10px;
              }
            }

            .unfold {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 15px;
              cursor: pointer;
            }
          }

          .right_right {
            width: 300px;
            height: 112px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 7px;
            // padding-bottom: 20px;
            // border-bottom: 1px solid #eeeeee;

            .right_price {
              font-size: 14px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #777a82;

              .price {
                font-size: 16px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #f34417;
              }
            }

            .right_btn {
              margin-top: 40px;
            }
          }
        }
      }

      .allOrdersCent:nth-last-child(1) {
        .right {
          border: none !important;

          .right_right {
            height: auto !important;
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .bottom_tip {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #333333;

          .open {
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }

      .right_btn {
        display: flex;
        align-items: center;

        .btn1 {
          width: 110px;
          height: 36px;
          border-radius: 18px 18px 18px 18px;
          border: 1px solid #b9bfc4;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #777a82;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;
        }

        .btn1:nth-last-child(1) {
          margin-right: 0px;
        }

        .btn2 {
          width: 110px;
          height: 36px;
          border-radius: 18px 18px 18px 18px;
          border: 1px solid #ff5d51;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff5d51;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-right: 20px;
        }

        .btn2:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
  }
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.flex_co {
  display: flex;
  flex-direction: column;
}

.iframeBox {
  width: 100%;
  height: 800px;

  .iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0px !important;
  }

  .el-tabs__item {
    padding-top: 24px;
    padding-bottom: 15px;
    height: auto !important;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }

  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .el-tabs__active-bar {
    width: 24px;
    height: 3px;
    background: #ff5d51;
    border-radius: 1px 1px 1px 1px;
    opacity: 1;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background: #ececec;
  }
}

.nothing {
  height: auto;
}

.popup_wrap {
  height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .CR_code_box {
    width: 260px;
    height: 260px;
    background-color: #fff4f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 42px;
    position: relative;

    .QR_code_img {
      width: 160px;
      height: 160px;
      border-radius: 8px;
    }

    .block {
      width: 60px;
      height: 60px;
      position: absolute;
    }

    .upper_left {
      border-top: 6px solid #ff5e51;
      border-left: 6px solid #ff5e51;
      top: 0;
      left: 0;
    }

    .top_right {
      border-top: 6px solid #ff5e51;
      border-right: 6px solid #ff5e51;
      top: 0;
      right: 0;
    }

    .lower_left {
      border-bottom: 6px solid #ff5e51;
      border-left: 6px solid #ff5e51;
      bottom: 0;
      left: 0;
    }

    .lower_right {
      border-bottom: 6px solid #ff5e51;
      border-right: 6px solid #ff5e51;
      bottom: 0;
      right: 0;
    }
  }

  .contact_details {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 20px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 11px;
    }
  }

  .text_box {
    font-size: 20px;
    margin-top: 30px;

    .red_text {
      color: #ff5e51;
    }
  }
}

.appraiseVisible {
  /deep/ .el-dialog {
    height: 400px;
    background: linear-gradient(180deg, #c5d2ff 0%, #ffffff 68%, #ffffff 100%);
    // background: url("~@/assets/img/textmall/estimate_bg.png") no-repeat;
    // background-size: 100% 75%;
    // box-shadow: none;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    position: relative;
    margin-top: 30vh !important;

    .el-dialog__header {
      position: relative;
      padding-top: 50px;
      z-index: 5 !important;

      .el-dialog__title {
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 0px;
      }
    }

    .appraiseVisible_img {
      position: absolute;
      top: -30px;
      left: 20px;
    }

    .appraise {
      margin-top: 5px;

      .el-rate__item {
        .el-rate__icon {
          font-size: 26px;
          border: none;
        }
      }

      .el-rate__text {
        margin-left: 15px;
        font-size: 14px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #ff5d51 !important;
      }
    }

    .centent {
      margin-top: 20px;

      .el-textarea__inner {
        height: 130px;
        padding-top: 14px;
        color: #999;
      }
    }

    .flie {
      margin-top: 10px;

      .el-upload--picture-card {
        width: 50px;
        height: 50px;
        line-height: 62px;
      }

      .el-upload-list__item-actions {
        width: 50px;
        height: 50px;
      }

      .el-upload-list__item {
        width: 50px;
        height: 50px;
        margin: 0 8px 0 0;
      }

      .el-upload-list__item-thumbnail {
        width: 50px;
        height: 50px;
      }
    }

    .footer {
      margin-top: 10px;

      .btn {
        width: 360px;
        height: 50px;
        background: #ff5d51;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: none;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}

.examine {
  .examine_img {
    position: absolute;
    top: -30px;
    left: 10px;
  }

  /deep/ .el-dialog {
    height: 310px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin-top: 30vh !important;
    background: linear-gradient(180deg, #c5d2ff 0%, #ffffff 68%, #ffffff 100%);

    .el-dialog__header {
      padding-top: 50px;
      position: relative;
      z-index: 5;

      .el-dialog__title {
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 0px;
      }
    }

    .details {
      width: 354px;
      height: 190px;
      overflow-y: auto;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #efefef;
      margin-top: 17px;
      padding: 10px 23px;

      .rate {
        margin-left: -5px;

        .el-rate__item {
          .el-rate__icon {
            font-size: 32px;
            border: none;
          }
        }

        .el-rate__text {
          margin-left: 15px;
          font-size: 14px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #ff5d51 !important;
        }
      }

      .title {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 20px;
      }

      .detail_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .item_img {
          width: 66px;
          height: 66px;
          margin-right: 10px;

          .img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }

        .item_img:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
<style lang="less">
/deep/ .el-dialog {
  border-radius: 10px !important;
}

.ssss {
  .el-dialog {
    border-radius: 10px !important;
  }

  .el-dialog__header {
    font-weight: 800;
    border-bottom: 1px solid #DDDDDD;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}

.el-image-viewer__wrapper {
  z-index: 9999 !important;
}

/* .el-image-viewer__close {
  top: 80px !important;
} */
.el-message-box {
  width: 356px;
  height: 230px;
  background: linear-gradient(180deg,
      rgba(255, 223, 221, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
  border-radius: 11px 11px 11px 11px;
  opacity: 1;
  border: none;
}

.el-message-box__title {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  letter-spacing: 2px;
}

.el-message-box__headerbtn {
  font-size: 26px;
  color: #666666;
  opacity: 1;
  top: 25px;
}

.el-message-box__content {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center !important;
  margin: 20px 0;
  /* letter-spacing: 1px; */
}

.el-button--primary {
  background-color: transparent !important;
  border: none;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #ff5d51 !important;
  font-weight: bold;
  letter-spacing: 2px;
}

.el-descriptions-row {
  .el-descriptions-item__cell:nth-child(1) {
    width: 100px;
  }
}

.el-descriptions__table {
  tbody:nth-child(2n-1) {
    background: #F9F9F9;
  }
}
</style>
